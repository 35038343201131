export const SECTIONS = [
    {
      title: "100% Secure Payments",
      desc: "All major credit & debit cards accepted.",
      imageUrl:
        "https://media6.ppl-media.com/mediafiles/ecomm/home/1499247950_secure-payment.jpg",
      path: "javascript:void(0)",
    },
    {
      title: "Beauty Assistant",
      desc: "Tell me what you are looking for and i will work my magic to help you find your perfect match.",
      imageUrl:
        "https://media6.ppl-media.com/mediafiles/ecomm/home/1499247975_beauty-assistant.jpg",
      path: "/discover/beautyassistant",
    },
    {
      title: "Help Center",
      desc: "Got a question? Look no further. Browse FAQs or submit your query.",
      imageUrl:
        "https://media6.ppl-media.com/mediafiles/ecomm/home/1499247997_help-center.jpg",
      path: "/wv/support/home",
    },
  ];
  
  export const META_LINKS = [
    {
      title: "DISCOVER:",
      url: "/discover",
      items: [
        {
          web_target_url: "/thread/nail-art",
          name: "Nail Art",
        },
        {
          web_target_url: "/thread/eye-makeup",
          name: "Eye Makeup",
        },
        {
          web_target_url: "/thread/bridal-makeup",
          name: "Bridal Makeup",
        },
        {
          web_target_url: "/thread/makeup-tutorials",
          name: "How To Do Makeup",
        },
        {
          web_target_url: "/thread/pimples",
          name: "Pimples",
        },
        {
          web_target_url: "/thread/stretch-marks",
          name: "Stretchmark Removal",
        },
        {
          web_target_url: "/thread/best-eye-creams--serums",
          name: "Best Eye Creams",
        },
        {
          web_target_url: "/thread/hairstyles",
          name: "Hairstyles",
        },
        {
          web_target_url: "/thread/burgundy-hair-colors",
          name: "Burgundy Hair Colors",
        },
        {
          web_target_url: "/thread/best-facewashes-for-men",
          name: "Best Facewashes For Men",
        },
        {
          web_target_url: "/thread/best-perfumes-for-men",
          name: "Best Perfumes For Men",
        },
        {
          web_target_url: "/thread/skincare-tips",
          name: "Skincare Tips",
        }
      ],
    },
    {
      title: "SHOP MAKEUP:",
      url: "/makeup",
      items: [
        {
          web_target_url: "/brand/lakme",
          name: "Lakme",
        },
        {
          web_target_url: "/brand/maybelline",
          name: "Maybelline",
        },
        {
          web_target_url: "/brand/colorbar",
          name: "Colorbar",
        },
        {
          web_target_url: "/brand/loreal",
          name: "L'oreal",
        },
        {
          web_target_url: "/brand/revlon",
          name: "Revlon",
        },
        {
          web_target_url: "/brand/avon",
          name: "Avon",
        },
        {
          web_target_url: "/brand/elle18",
          name: "Elle18",
        },
      ],
    },
    {
      title: "SKIN CARE:",
      url: "/skin",
      items: [
        {
          web_target_url: "/brand/bio-oil",
          name: "Bio Oil",
        },
        {
          web_target_url: "/brand/olay",
          name: "Olay",
        },
        {
          web_target_url: "/brand/neutrogena",
          name: "Neutrogena",
        },
        {
          web_target_url: "/brand/lotus",
          name: "Lotus Herbals",
        },
        {
          web_target_url: "/brand/vlcc",
          name: "VLCC",
        },
        {
          web_target_url: "/brand/kaya",
          name: "Kaya",
        },
        {
          web_target_url: "/brand/vichy",
          name: "Vichy",
        },
        {
          web_target_url: "/brand/nivea",
          name: "Nivea",
        },
        {
          web_target_url: "/brand/gillette",
          name: "Gillette",
        },
        {
          web_target_url: "/brand/park-avenue",
          name: "Park Avenue",
        },
      ],
    },
    {
      title: "HAIR PRODUCTS:",
      url: "/hair",
      items: [
        {
          web_target_url: "/brand/loreal-professionnel",
          name: "L'oreal Professionnel",
        },
        {
          web_target_url: "/brand/schwarzkopf",
          name: "Schwarzkopf",
        },
        {
          web_target_url: "/brand/matrix",
          name: "Matrix Biolage",
        },
        {
          web_target_url: "/product/livon-hair-gain-tonic-150-ml",
          name: "Livon Hair Gain",
        },
        {
          web_target_url: "/brand/biotique",
          name: "Biotique",
        },
        {
          web_target_url: "/brand/roots",
          name: "Roots",
        },
      ],
    },
    {
      title: "FRAGRANCE:",
      url: "/fragrance",
      items: [
        {
          web_target_url: "/brand/davidoff",
          name: "Davidoff",
        },
        {
          web_target_url: "/brand/hugo-boss",
          name: "Hugo Boss",
        },
        {
          web_target_url: "/brand/calvin-klein",
          name: "Calvin Klein",
        },
        {
          web_target_url: "/brand/elizabeth-arden",
          name: "Elizabeth Arden",
        },
        {
          web_target_url: "/brand/jaguar",
          name: "Jaguar",
        },
        {
          web_target_url: "/brand/victorias-secret",
          name: "Victoria's Secret",
        },
      ],
    },
    {
      title: "ELECTRONICS:",
      url: "/electronics",
      items: [
        {
          web_target_url: "/brand/philips",
          name: "Philips",
        },
        {
          web_target_url: "/brand/wahl",
          name: "Wahl",
        },
        {
          web_target_url: "/brand/braun",
          name: "Braun",
        },
        {
          web_target_url: "/brand/remington",
          name: "Remington",
        },
      ],
    },
  ];
  
  export const GROUPED_LINKS = [
    {
      heading: "Purplle",
      items: [
        {
          title: "About Us",
          path: "/pr/about-us",
        },
        {
          title: "Our Team",
          path: "/pr/our-team",
        },
        {
          title: "Careers",
          path: "/careers",
        },
        {
          title: "Press",
          path: "/pr/press",
        },
        {
          title: "Sitemap",
          path: "/sitemap.xml",
        },
        {
          title: "Compliance",
          path: "/pr/compliance",
        },
      ],
    },
    {
      heading: "Privacy Info",
      items: [
        {
          title: "Privacy Policy",
          path: "/pr/privacy-policy",
        },
        {
          title: "Terms of Use",
          path: "/pr/terms-and-condition",
        },
        {
          title: "Return & Cancellation Policy",
          path: "/pr/cancellation-and-returns",
        },
      ],
    },
    {
      heading: "Need Help?",
      items: [
        {
          title: "FAQ's",
          path: "wv/support/allfaq",
        },
        {
          title: "Contact Us",
          path: "/contactus",
        },
      ],
    },
  ];
  
  export const SOCIAL_LINKS = [
    {
      name: "facebook",
      iconName: "p-facebook",
      backgroundColor: "#39579a",
      path: "http://www.facebook.com/letspurplle",
    },
    {
      name: "twitter",
      iconName: "p-social-twitter",
      backgroundColor: "#00abf0",
      path: "http://twitter.com/letspurplle",
    },
    {
      name: "Google Plus",
      iconName: "p-google-plus",
      backgroundColor: "#ed3833",
      path: "https://plus.google.com/+Purplle",
    },
    {
      name: "Pinterest",
      iconName: "p-pinterest-p",
      backgroundColor: "#cd1d1f",
      path: "https://in.pinterest.com/letspurplle/",
    },
    {
      name: "Mail",
      iconName: "p-email-outline",
      backgroundColor: "#555",
      path: "mailto:customercare@purplle.com",
    },
    {
      name: "Youtube",
      iconName: "p-social-youtube-outline",
      backgroundColor: "#ed3833",
      path: "https://www.youtube.com/channel/UCI_6AcJI1sKexCLb2NAYTOQ",
    },
    {
      name: "Instagram",
      iconName: "p-social-instagram-outline",
      backgroundColor: "#e91b90",
      path: "https://www.instagram.com/letspurplle/",
    },
    {
      name: "LinkedIn",
      iconName: "p-linkedin",
      backgroundColor: "#397db3",
      path: "https://www.linkedin.com/company/purplle-com",
    },
  ];
  
  export const USEFUL_LINKS = [
    {
        name: 'About Us',
        path: '/pr/about-us'
    },
    {
        name: 'Press',
        path: '/pr/press'
    },
    {
        name: 'Sitemap',
        path: '/sitemap.xml'
    },
    {
        name: 'Compliance',
        path: '/pr/compliance'
    },
    {
        name: 'Sell on Purplle',
        path: 'https://docs.google.com/forms/d/e/1FAIpQLSc_xYcBU3GQ4wPmTbmYbcPy5CiRyogra1WCbwnLhGkbaC2yVQ/viewform'
    },
    {
        name: 'Privacy Policy',
        path: '/pr/privacy-policy'
    },
    {
        name: 'Terms of Use',
        path: '/pr/terms-and-condition'
    },
    {
        name: 'Return & Cancellation Policy',
        path: '/pr/cancellation-and-returns'
    },
    {
        name: 'Contact Us',
        path: '/contactus'
    },
]